<template>
  <div class="alarmList">
    <div class="alarmBox">
      <div class="title">
        <h3>
          <i class="material-icons"> notifications_none </i>
          알림
        </h3>
      </div>
      <ul class="nt_box">
        <li v-for="(item, i) in messageListInfo" :key="i">
          <a
            ><span>{{ item.title }}</span></a
          >
          <a class="nt_message">{{ item.message }} </a>
          <span>{{
            moment(item.created_at).format("YYYY.MM.DD a hh:mm")
          }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { newAlarm, clickAlarm } from "@/api/member";
import moment from "moment";
export default {
  name: "alarm",
  data() {
    return {
      messageListInfo: [],
      showMsg: false,
      newMsg: "",
      moment: moment,
    };
  },
  mounted() {
    this.clickMsg();
    this.newMsgCount();
  },
  methods: {
    newMsgCount() {
      newAlarm().then((res) => {
        this.newMsg = res.data.alarmCount;
        if (this.newMsg == 0) {
          this.newMsg = "";
        }
      });
    },
    clickMsg() {
      this.showMsg = true;
      clickAlarm().then((res) => {
        this.messageListInfo = res.data.messageInfo;
        this.newMsgCount();
      });
    },
  },
};
</script>
